import React from 'react'
import {gridManager, current_worldstate} from "./backend_manager";
import UserIcon from "../frontend/IconManager";

//Parent class of the different constructable objects
class world_object{
    constructor(){
        //level of construction
        this._level = 1;
        // destroyed, being repaired or whole
        this._state = 0;
        //before the tutorial not purchasable but then user gets money and is told to buy first unit
        this._purchasable = true;
        this._upgradable = false;

        //default icon
        this._icon = <UserIcon name="car_factory"/>
        this._icon_large = <UserIcon name="car_factory" size="150px"/>
        this._cName = "carbon-card";
        this._text = "Insert some funny text...";
    }

    // select item for placement
    select(){
        gridManager.select(this);
        this._card.setState({ clicked: true });
    }

    // deselct previously selected item for placement
    deselect(){
        gridManager.deselect(this);
        this.detach()
    }

    // detach card from user mouse
    detach(){
        this._card.setState({ clicked: false });
    }

    buy(){
        current_worldstate.update_on_buy(this);
        return 0;
    }

    get cost(){
        return this._cost;
    }
    
    get energy_cost(){
        return this._energy_cost;
    }
    
    get co2_cost(){
        return this._co2_cost;
    }

    get upgrade_cost(){
        return this._upgrade_cost;
    }
    
    get upgrade_energy_cost(){
        return this._upgrade_energy_cost;
    }
    
    get upgrade_co2_cost(){
        return this._upgrade_co2_cost;
    }
    
    get money_income_rate(){
        return this._money_income_rate;
    }
    
    get energy_production_rate(){
        return this._energy_production_rate;
    }
    
    get co2_production_rate(){
        return this._co2_production_rate;
    }
    
    get level(){
        return this._level;
    }
    
    get state(){
        return this._state;
    }

    get icon(){
        return this._icon;
    }

    get icon_large(){
        return this._icon_large;
    }

    get cName(){
        return this._cName;
    }

    get name() {
        return this._name;
    }

    get text() {
        return this._text;
    }

    setCard(card) {
        this._card = card;
    }
    
    set_sale_state(state){
        this._purchasable = state;
        if(this._card != null){
            this._card.updateCard();
        }
    }

    set_upgrade_state(state){
        this._upgradable = state;
    }
    
    get purchasable(){
        return this._purchasable;
    }

    get upgradable(){
        return this._upgradable;
    }
    //TO BE BALANCED
    upgrade(){
        this._level += 1;
        this._cost *= 2;
        this._energy_cost *=2;
        this._co2_cost *=2;
        this._money_income_rate *=2;
        this._energy_production_rate *=2;
        this._co2_production_rate *=2;
    }

}
//Money production

class museum extends world_object{
    constructor(){
        super()
        this._cost = 200;
        this._energy_cost = 50;
        this._money_income_rate = 20;
        this._energy_production_rate = -10;
        this._co2_cost = 40;
        this._co2_production_rate = 10; //heating or so

        this._icon = <UserIcon name="museum"/>;
        this._icon_large = <UserIcon name="museum" size="150px"/>
      
        this._name = "Museum"
        this._text = "Backbone of the economy"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class food_court extends world_object{
    constructor(){
        super()
        this._cost = 300;
        this._energy_cost = 200;
        this._money_income_rate = 50;
        this._energy_production_rate = -25;
        this._co2_cost = 100;
        this._co2_production_rate = 15;

        this._icon = <UserIcon name="food_court"/>
        this._icon_large = <UserIcon name="food_court" size="150px"/>
        this._name = "Food Court"
        this._text = "Meat & Fish = CO2"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class clothing_company extends world_object{
    constructor(){
        super()
        this._cost = 500;
        this._energy_cost = 100;
        this._money_income_rate = 80;
        this._energy_production_rate = -40;
        this._co2_cost = 50;
        this._co2_production_rate = 150;

        this._icon = <UserIcon name="clothing_store"/>
        this._icon_large = <UserIcon name="clothing_store" size="150px"/>
        this._name = "Clothing Store"
        this._text = "Fast Fashion = CO2"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class office extends world_object{
    constructor(){
        super()
        this._cost = 1000;
        this._energy_cost = 500;
        this._money_income_rate = 150;
        this._energy_production_rate = -100;
        this._co2_cost = 50;
        this._co2_production_rate = 50;

        this._icon = <UserIcon name="office"/>
        this._icon_large = <UserIcon name="office" size="150px"/>
        this._name = "Office"
        this._text = "Money > Environment"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class car_industry extends world_object{
    constructor(){
        super()
        this._cost = 2500;
        this._energy_cost = 1000;
        this._money_income_rate = 250;
        this._energy_production_rate = -1000;
        this._co2_cost = 1000;
        this._co2_production_rate = 300;

        this._icon = <UserIcon name="car_factory"/>
        this._icon_large = <UserIcon name="car_factory" size="150px"/>
        this._name = "Car Factory"
        this._text = "CO2 go VRRROM"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

//Energy Production


class coal_plant extends world_object{
    constructor(){
        
        super()
        this._cost = 500;
        this._energy_cost = 100;
        this._money_income_rate = -20;
        this._energy_production_rate = 250;
        this._co2_cost = 100;
        this._co2_production_rate = 100;
        this._icon = <UserIcon name="coal_power_plant"/>
        this._icon_large = <UserIcon name="coal_power_plant" size="150px"/>
        this._name = "Coal Power Plant"
        this._text = "2/3 of Chinas energy"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = upgrade_cost;
        // this._upgrade_energy_cost = upgrade_energy_cost;
        // this._upgrade_co2_cost = upgrade_co2_cost;


        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){
        
    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class petroleum_plant extends world_object{
    constructor(){
        
        super()
        this._cost = 700;
        this._energy_cost = 150;
        this._money_income_rate = -30;
        this._energy_production_rate = 350;
        this._co2_cost = 100;
        this._co2_production_rate = 110;
        this._name = "Petroleum Power Plant"
        this._text = "Oil is finite"
        this._icon = <UserIcon name="petroleum_power_plant"/>
        this._icon_large = <UserIcon name="petroleum_power_plant" size="150px"/>
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = upgrade_cost;
        // this._upgrade_energy_cost = upgrade_energy_cost;
        // this._upgrade_co2_cost = upgrade_co2_cost;


        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class nuclear_power_plant extends world_object{
    constructor(){
        
        super();
        this._cost = 1500;
        this._energy_cost = 2000;
        this._co2_cost = 0;
        this._money_income_rate = -20;
        this._energy_production_rate = 500;
        this._co2_production_rate = 10;
        this._name = "Nuclear Power Plant"
        this._text = "3.6? not great, not terrible"
        this._icon = <UserIcon name="nuclear_power_plant"/>
        this._icon_large = <UserIcon name="nuclear_power_plant" size="150px"/>
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = upgrade_cost;
        // this._upgrade_energy_cost = upgrade_energy_cost;
        // this._upgrade_co2_cost = upgrade_co2_cost;
        
        // this._level = level;
        // this._state = whole;
        
        //if nuclear plant destroyed all buildings in threat surrounding also destroyed
        //additional idea of maintenance costs
        // this._threat = 5*this._level;
    }
    // upgrade(){
        
    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class solar_panel extends world_object{
    constructor(){
        super()
        this._cost = 500;
        this._energy_cost = 800;
        this._money_income_rate = 0;
        this._energy_production_rate = 125;
        this._co2_cost = 0;
        this._co2_production_rate = 10;
        this._name = "Solar Panel"
        this._text = "The sun is new each day"
        this._icon = <UserIcon name="solar_panel"/>
        this._icon_large = <UserIcon name="solar_panel" size="150px"/>
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = upgrade_cost;
        // this._upgrade_energy_cost = upgrade_energy_cost;
        // this._upgrade_co2_cost = upgrade_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){
        
    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class windmill extends world_object{
    constructor(){
        super()
        this._cost = 800;
        this._energy_cost = 200;
        this._money_income_rate = 0;
        this._energy_production_rate = 125;
        this._co2_cost = 50;
        this._co2_production_rate = 5;
        this._name = "Wind Power Plant"
        this._text = "Words are wind"
        this._icon = <UserIcon name="windmill"/>
        this._icon_large = <UserIcon name="windmill" size="150px"/>

        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = upgrade_cost;
        // this._upgrade_energy_cost = upgrade_energy_cost;
        // this._upgrade_co2_cost = upgrade_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){
        
    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class water_plant extends world_object{
    constructor(){
        super()
        this._cost = 1400;
        this._energy_cost = 1500;
        this._money_income_rate = -10;
        this._energy_production_rate = 400;
        this._co2_cost = 100;
        this._co2_production_rate = 10;
        this._name = "Water Power Plant"
        this._text = "Are you a hydroHomie?"
        this._icon = <UserIcon name="water_power_plant"/>
        this._icon_large = <UserIcon name="water_power_plant" size="150px"/>
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = upgrade_cost;
        // this._upgrade_energy_cost = upgrade_energy_cost;
        // this._upgrade_co2_cost = upgrade_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){
        
    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}


//CO2 reduction
class tree_acre extends world_object{
    constructor(){
        super()
        this._cost = 100;
        this._energy_cost = 10;
        this._money_income_rate = 0;
        this._energy_production_rate = 0;
        this._co2_cost = 0;
        this._co2_production_rate = -15;

        this._icon = <UserIcon name="tree"/>
        this._icon_large = <UserIcon name="tree" size="150px"/>
        this._name = "Trees"
        this._text = "I'm a tree."
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class energy_vault extends world_object{
    constructor(){
        super()
        this._cost = 1500;
        this._energy_cost = 150;
        this._money_income_rate = -100;
        this._energy_production_rate = 0;
        this._co2_cost = 20;
        this._co2_production_rate = -50;

        this._icon = <UserIcon name="energy_vault"/>
        this._icon_large = <UserIcon name="energy_vault" size="150px"/>
        this._name = "Energy Vault"
        this._text = "Simply stores CO2"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class prebiotic_electrochem_red extends world_object{
    constructor(){
        super()
        this._cost = 2500;
        this._energy_cost = 350;
        this._money_income_rate = -50;
        this._energy_production_rate = 75;
        this._co2_cost = 0;
        this._co2_production_rate = -80;

        this._icon = <UserIcon name="prebiotic_co2_reduction"/>
        this._icon_large = <UserIcon name="prebiotic_co2_reduction" size="150px"/>
        this._name = "Prebiotic CO2 Reduction"
        this._text = "Climate-actimel"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class electrocatalyst extends world_object{
    constructor(){
        super()
        this._cost = 4000;
        this._energy_cost = 150;
        this._money_income_rate = -100;
        this._energy_production_rate = -100;
        this._co2_cost = 20;
        this._co2_production_rate = -150;

        this._icon = <UserIcon name="electrocatalyst"/>
        this._icon_large = <UserIcon name="electrocatalyst" size="150px"/>
        this._name = "Electrocatalyst"
        this._text = "Science helps"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}

class electro_chem_red extends world_object{
    constructor(){
        super()
        this._cost = 5000;
        this._energy_cost = 150;
        this._money_income_rate = -100;
        this._energy_production_rate = -200;
        this._co2_cost = 20;
        this._co2_production_rate = -200;

        this._icon = <UserIcon name="electrochemical_co2_reduction"/>
        this._icon_large = <UserIcon name="electrochemical_co2_reduction" size="150px"/>
        this._name = "Chemical CO2 removal"
        this._text = "Use in case of emergency"
        
        //how many objects of this type are already on the map
        // this._number_of_instances = 1;
        
        // this._upgrade_cost = update_cost;
        // this._upgrade_energy_cost = update_energy_cost;
        // this._upgrade_co2_cost = update_co2_cost;

        // this._level = level;
        // this._state = whole;
    }
    // upgrade(){

    //     this._purchasable = false;
    //     this._upgradable = false;
    // }
}


export {world_object, car_industry, office, food_court, museum, clothing_company, nuclear_power_plant, coal_plant, petroleum_plant, water_plant, solar_panel, windmill, tree_acre, energy_vault, prebiotic_electrochem_red, electrocatalyst, electro_chem_red};
