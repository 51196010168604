import { itemManager } from "./backend/backend_manager"


var totaltime = 0
var starttime = 0

var clicks = 0
var distance = 0

var prevMouseX = -1
var prevMouseY = -1

//var engine = null

var taskStep = 0
var purchasable = false

function start_output() {
    /*if (engine===null) {
        engine = setInterval(function () {
            console.log("Time: " + totaltime + "; Clicks: " + clicks + "; Distance: " + distance + "; Taskstep: " + taskStep);
        }, 1000)
    }*/
}

function setTask(task) {
    taskStep = task;
    purchasable = false;
    startTimer()
    itemManager.check_item_cost()
}

function addClick() {
    if (taskStep === 0 || taskStep === 7) {
        return;
    }
    clicks++;
}

function startTimer() {
    if (taskStep === 0 || taskStep === 7) {
        return;
    }
    var date = new Date();
    starttime = date.getTime()
    console.log("Timer started")
}

function setPurchasableTimer(card) {
    if (!purchasable) {
        if (card.name === "Museum" && taskStep === 1) {
            startTimer()
            purchasable = true;
        }
        if (card.name === "Trees" && taskStep === 2) {
            startTimer()
            purchasable = true;
        }
        if (card.name === "Coal Power Plant" && taskStep === 3) {
            startTimer()
            purchasable = true;
        }
        if (card.name === "Solar Panel" && taskStep === 4) {
            startTimer()
            purchasable = true;
        }
        if (card.name === "Petroleum Power Plant" && taskStep === 5) {
            startTimer()
            purchasable = true;
        }
        if (card.name === "Nuclear Power Plant" && taskStep === 6) {
            startTimer()
            purchasable = true;
        }
    }
}

function stopTimer() {
    if (starttime === 0) {
        return;
    }
    var date = new Date();
    totaltime += date.getTime() - starttime;
    starttime = 0;
    console.log("Timer stopped")
    console.log("Time: " + totaltime + "; Clicks: " + clicks + "; Distance: " + distance + "; Taskstep: " + taskStep);
}

function mouseMove(mouseX, mouseY) {
    if (taskStep === 0 || taskStep === 7) {
        return;
    }
    if (prevMouseX !== -1) {
        var xDiff = mouseX - prevMouseX;
        var yDiff = mouseY - prevMouseY;
        distance += Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    }
    prevMouseX = mouseX;
    prevMouseY = mouseY;
}

export { addClick, startTimer, stopTimer, mouseMove, start_output, setTask, setPurchasableTimer }
