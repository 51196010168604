import React, { Component } from 'react'
import '../../../css/Card.css'
import UserIcon from '../IconManager';

export default class Card extends Component {
  constructor(props) {
    super(props); // call the parent constructor
    this.state = {
      item: this.props.item,
      clicked: false,
      // set the initial css for the drag items s.t they load properly
      unclicked: {
        overflow: 'hidden',
        position: 'fixed',
        height: 0,
        width: 0
      },
      clickedStyle: {
        position: 'fixed',
        height: 75,
        width: 75,
        top: 0, //item hovers 20px above cursor
        left: 0, //center item above cursor horizontally
      },
    };
    /* pass this card to the worlditem, s.t. worlditem can call updateCard() once
      it becomes purchasable */
    this.state.item.setCard(this);
  }

  //this gets called on mouse move -> updates the position of the dragged icon
  updateMousePosition = e => {
    this.setState({
      clickedStyle: {
        position: 'fixed',
        height: 75,
        width: 75,
        top: e.clientY - 77, //item hovers 20px above cursor
        left: e.clientX - 77, //center item above cursor horizontally
      }
    });
  };

  /* gets called from the world item upon updating purchasibility */
  updateCard() {
    this.setState({ item: this.props.item });
  }

  // Display object information on hover:
  handleEnter() {
    this.props.selectItem(this.state.item);
  }
  handleLeave() {
    // dont display item information anymore
    this.props.selectItem(null);
  }

  /*handles the click on the card itself */
  handleClick() {
    if (this.state.item.purchasable) {
      this.props.placeItem();
      this.state.item.select();
    }
  }

  /* render html code, gets triggered  on this.setState() */
  render() {
    return (
      <>
        <button id={this.state.item.cardId} className={"buycard" + (this.state.item.purchasable ? ' selectable' : '')}
          onClick={() => this.handleClick()}
          onMouseEnter={() => this.handleEnter()}
          onMouseLeave={() => this.handleLeave()}>
          {/* <h>{this.state.item.name}</h> */}
          <div className="gameobject-type-overlay">
            <UserIcon name={this.props.type} size="35px" />
          </div>
          <div className="gameobject-icon">
            {this.state.item.icon}
          </div>
          <div className="gameobject-price">
            <UserIcon name="coin" size="20px" />
            {this.state.item.cost}
          </div>
        </button>
      </>
    );
  }
}
