import { setPurchasableTimer } from '../data_collection';
import { current_worldstate } from './backend_manager';
import { car_industry, office, food_court, museum, clothing_company, nuclear_power_plant, coal_plant, petroleum_plant, water_plant, solar_panel, windmill, tree_acre, energy_vault, prebiotic_electrochem_red, electro_chem_red, electrocatalyst } from './world_items';


//Class to build, repair and check the items
class item_manager {
    constructor(/*nuclear, coal, petrol, water, solar, trees /*, grid*/) {
        // order of the lists defines their order in the menu
        this._cashmachines = [new museum(), new food_court(), new clothing_company(), new office(), new car_industry()];
        this._powercards = [new coal_plant(), new petroleum_plant(), new solar_panel(), new windmill(), new nuclear_power_plant(), new water_plant()]
        this._carbonremovals = [new tree_acre(), new energy_vault(), new prebiotic_electrochem_red(), new electrocatalyst(), new electro_chem_red()];
        this._item_list = this._powercards.concat(this._carbonremovals).concat(this._cashmachines);
    }

    //For each item subclass determine whether we can buy it this iteration
    check_item_cost() {
        for (let i = 0; i < this._item_list.length; i++) {
            if ((this._item_list[i].cost !== 0 && current_worldstate.money < this._item_list[i].cost) || (this._item_list[i].energy_cost !== 0 && current_worldstate.energy < this._item_list[i].energy_cost))
                this._item_list[i].set_sale_state(false);
            else {
                this._item_list[i].set_sale_state(true);
                setPurchasableTimer(this._item_list[i]);
            }
        }
    }


    //Not needed purchase functions as we do it through item directly
    // //Purchase functions:
    // //Update worldstate stats through purchase, subtract cost from bank and adjust ecological balance
    // buy_car_industry() {
    //     current_worldstate.update(this._item_list[0])
    //     //place item on grid 
    // }

    // buy_office() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    // buy_food_court() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    // buy_clothing_company() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    // buy_museum() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    // buy_nuclear() {
    //     current_worldstate.update(this._item_list[0])
    //     //place item on grid 
    // }

    // buy_coal() {
    //     current_worldstate.update(this._item_list[1])
    //     //place item on grid 
    // }

    // buy_petrol() {
    //     current_worldstate.update(this._item_list[2])
    //     //place item on grid 
    // }

    // buy_water() {
    //     current_worldstate.update(this._item_list[3])
    //     //place item on grid 
    // }

    // buy_solar() {
    //     current_worldstate.update(this._item_list[4])
    //     //place item on grid 
    // }

    // buy_trees() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    // buy_energy_vault() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    // buy_electrochem_red() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    // buy_prebiotic_electrochem_red() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    // buy_electrocatalyst() {
    //     current_worldstate.update(this._item_list[5])
    //     //place item on grid 
    // }

    get powercards() {
        return this._powercards;
    }

    get carbonremovals() {
        return this._carbonremovals;
    }

    get cashmachines() {
        return this._cashmachines;
    }

    //Returns Cost of most expensive Building
    //Used for progressbars
    getMaxCosts() {
        var max = 0;
        this._item_list.forEach((plant) => {
            max = Math.max(max, plant.cost);
        });
        return max;
    }

    //Returns EnergyCost of most energyexpensive Building
    //Used for progressbars
    getMaxEnergyCosts() {
        var max = 0;
        this._item_list.forEach((plant) => {
            max = Math.max(max, plant.energy_cost);
        });
        return max;
    }


    // check_upgradecost(){
    //     for(let i = 0; i < length(this._item_list);i++){
    //         if(current_worldstate.money() < this._item_list[i].update_cost() || current_worldstate.energy() < this._item_list[i].update_energy_cost())
    //             this._item_list[i].set_sale_state(false);
    //         else
    //             this._item_list[i].set_sale_state(true);
    //     }
    // }


    //update functions

    // upgrade_nuclear(){
    //     this._item_list[0].upgrade();
    //     current_worldstate.update(this._item_list[i])
    // }

    // upgrade_coal(){
    //     this._item_list[1].upgrade();
    //     current_worldstate.update(this._item_list[i])
    // }

    // upgrade_petrol(){
    //     this._item_list[2].upgrade();
    //     current_worldstate.update(this._item_list[i])
    // }

    // upgrade_water(){
    //     this._item_list[3].upgrade();
    //     current_worldstate.update(this._item_list[i])
    // }

    // upgrade_solar(){
    //     this._item_list[4].upgrade();
    //     current_worldstate.update(this._item_list[i])
    // }

    // upgrade_trees(){
    //     this._item_list[5].upgrade();
    //     current_worldstate.update(this._item_list[i])
    // }


    // repair_object(testObject){
    //     //pay repairment costs and wait repairment time to unlock that plant again
    // }
}

export default item_manager;
