import { current_worldstate } from "./backend_manager";
import { stopTimer } from "../data_collection";


class grid_manager {
    constructor() {
        this._grid = new Array(64).fill(null);
        this._selected = null;
        this._bulldozing = false;
    }

    get grid() {
        return this._grid;
    }

    get selected() {
        return this._selected;
    }

    get bulldozing(){
        return this._bulldozing;
    }

    // select item for purchase, deselect any previously selected items
    select(item) {
        if (this._selected != null) {
            this._selected.deselect();
        }
        this._selected = item;
    }

    // DONT CALL DIRECTLY, deselect item for purchase (gets called from item)
    deselect() {
        if (this._selected != null) {
            this._selected = null;
        }
    }

    // detach selected item from mouse, DONT USE; Use the items detach function directly instead
    detach_selected() {
        if (this._selected != null) {
            this._selected.detach();
        }
    }

    setBulldozing(value){
        this._bulldozing = value;
    }

    fill_cell(row, col) {
        if(this._bulldozing){
            this._grid[row * 8 + col] = null;
            this._bulldozing = false;
            current_worldstate.update();
            return;
        }
        if (this._selected === null)
            console.log("nothing selected in menu to be placed")
        else {
            console.log("placing selected item in backend grid");
            console.log(row, col);
            //Check if selected grid tile is empty
            if (this._grid[row * 8 + col] === null) {
                this._grid[row * 8 + col] = this._selected;
                // buy the item
                this._selected.buy();
                // detach it from mouse and deselect it 
                this._selected.deselect();    
                stopTimer()        
            }
        }
    }
}

export { grid_manager }