import React, { Component } from 'react';
import '../css/main.css';
import Grid from './frontend/Grid';
import BuyMenu from './frontend/BuyMenu';
import Bulldozer from './frontend/drag_objects/Bulldozer';
import PlaceIcon from './frontend/drag_objects/PlaceIcon';
import ActionMenu from './frontend/ActionMenu';
import { current_worldstate, initBackend, gridManager, getGraphData, stop_engine, start_engine } from './backend/backend_manager';
import { StyledEngineProvider } from '@mui/material/styles';
import MoneyBar from './frontend/Bars_n_Graphs/MoneyBar';
import ImageTutorial from './frontend/image_tutorial';
import PauseScreen from './frontend/PauseScreen';
//import NewsTicker from './frontend/NewsArticle_horizontal';
import "../css/Dialog.css";
import TempChart from './frontend/Bars_n_Graphs/TempChart';
import { addClick, mouseMove, start_output } from './data_collection'

// launches all the backend js files
export default class App extends Component {
  constructor(props) {
    super(props); // call the parent constructor
    initBackend(this)
    start_output()
    const mouse_Move = e => {
      mouseMove(e.offsetX, e.offsetY)
    }
    window.addEventListener("mousemove", mouse_Move);
    //need to register click like this since we want to notice when the user clicks  anywhere when he has an object attached
    window.addEventListener("mousedown", addClick);

    this.state = {
      worldstate: current_worldstate, // react only update the render if state variables change so we need to have them in here and not in the class
      gridContent: gridManager.grid,
      graphData: getGraphData(),
      action: "none",
      do_drag: false,
      running: true,
      inactive_count: 0
    };
  }

  // deselect any still selected items
  // stopp all other actions (bulldozing)
  abort() {
    if (gridManager.bulldozing) {
      gridManager.setBulldozing(false);
      this.forceUpdate();
    }
    if (gridManager.selected != null) {
      gridManager.select(null);
    }
  }

  // select an action context, action is a string {"none", "buy", "remove", "place", "tutorial", "news" ...}
  selectAction(_action) {
    this.abort()
    this.setState({ action: _action })
  }

  // right click anywhere at anytime aborts whatever we are doing
  handleRightClick = e => {
    e.preventDefault()
    this.selectAction("none")
  }

  componentDidMount() {
    window.addEventListener("contextmenu", this.handleRightClick);
    window.addEventListener("blur", this.onBlur)
  }

  componentWillUnmount() {
    window.removeEventListener("blur", this.onBlur)
  }

  placeItemOnGrid(row, col) {
    gridManager.fill_cell(row, col);
    this.setState({ gridContent: gridManager.grid });
    this.selectAction("none");
  }

  outsideOfGrid(value) {
    this.setState({ do_drag: value })
  }

  getDoDrag() {
    if (gridManager.selected != null) {
      return this.state.do_drag;
    } else {
      return false;
    }
  }

  update() {
    var count = this.state.inactive_count;
    if (this.state.action === "none") {
      count += 1; 
    } else {
      count = 0;
    }
    this.setState({ worldstate: current_worldstate, 
                    graphData: getGraphData(),
                    inactive_count: count})
    if (count > 5) {
      this.selectAction("news");
    }
  }

  unpause() {
    if (!this.state.running && this.state.action !== "tutorial") {
      start_engine();
      this.setState({running: true,
                      action: "none"})
    }
  }

  onBlur = () => {
    if (this.state.running && this.state.action !== "tutorial") {
      stop_engine();
      this.setState({running: false})
    }
  }

  render() {
    return (
      // apply our css before library css
      <StyledEngineProvider injectFirst>
        {this.state.running && 
        <>
        {/* show action options unless we are placing an item or removing, in that case show abort */}
        <ActionMenu selectAction={(action) => this.selectAction(action)}
          show_actions={this.state.action !== "remove" && this.state.action !== "place"} />
        <BuyMenu show_menu={this.state.action === "buy"} selectAction={(action) => this.selectAction(action)} />
        <ImageTutorial show_tutorial={this.state.action === "tutorial"} selectAction={(action) => this.selectAction(action)} />
        <TempChart data={this.state.graphData} />
        {/*<NewsTicker show_news={this.state.action === "news"} hideTicker={() => this.selectAction("none")}/>*/}
        <MoneyBar worldstate={this.state.worldstate} />
        {/* dragged icon for bulldozer */}
        <Bulldozer drag={this.state.action === "remove"} />
        {/* dragged icon for gameobject */}
        <PlaceIcon drag={this.getDoDrag()} item={gridManager.selected} />
        </>}
        {!this.state.running && <PauseScreen unpause={() => this.unpause()}/>}
        <Grid
          grid_content={this.state.gridContent}
          placeItem={(row, col) => this.placeItemOnGrid(row, col)}
          outsideOfGrid={(value) => this.outsideOfGrid(value)} />
      </StyledEngineProvider>
    );
  }
}
