import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BuyTabs from './buy_menu_components/BuyTabs'
import '../../css/BuyMenu.css'

export default class BuyMenu extends Component {
  constructor(props) {
    super(props); // call the parent constructor
    this.state = {
      show_menu: false, // react only update the render if state variables change so we need to have them in here and not in the class
    };
  }

  render() {
    return (
      // start html:
      <>
        <div className={this.props.show_menu ? 'buy-area show' : 'buy-area'}>
          {/* toggle-buy-menu button active if menu not shown */}
          <div className={this.props.show_menu ? 'buy-menu show' : 'buy-menu'}>
            <BuyTabs selectAction={(action) => this.props.selectAction(action)} />
          </div>
        </div>
      </> // end html
    )
  }
}

BuyMenu.propTypes = {
  show_menu: PropTypes.bool //ensure state is a bool
};