import React, { Component } from 'react';
import '../../css/image_tutorial.css';
import * as IoIcons from "react-icons/io"
import PropTypes from 'prop-types'
import {Tutorial} from './IconManager';
import { start_engine } from '../backend/backend_manager';


export default class ImageTutorial extends Component {
  constructor(props) {
    super(props); // call the parent constructor
    this.state = {
      step: 1
    };
  }
  
  abort() {
    start_engine()
    this.props.selectAction("none");
    this.setState({step: 1});
  }

  advanceTutorial() {
    // TODO limit this to the number of images
    if (this.state.step === 12) {
      this.abort();
    } else {
      this.setState({step: this.state.step+1});
    }
  }


  render() {
    return (
    // start html:
    <>
    <div className={this.props.show_tutorial ? 'tut-area show' : 'tut-area'}>
      {/* toggle-buy-menu button active if menu not shown */}
      <div className={this.props.show_tutorial ? 'tut-interface show' : 'tut-interface'}>
        <div className="tut-top-row">
          <div className={
              this.props.show_tutorial ? "close-tut-interface show" : "close-tut-interface"} 
              onClick={() => this.abort()}>
            <IoIcons.IoMdClose/>
          </div>
          Tutorial
        </div>
        <div className="tut-content" onClick={() => this.advanceTutorial()}>
            <Tutorial name={"tut" + this.state.step}/>
        </div>
        <div className="tut-bottom" onClick={() => this.advanceTutorial()}>
            Click anywhere to continue
        </div>

        {/* <BuyTabs placeItem={() => this.props.selectAction("place")}/> */}
      </div>
    </div>
  </> // end html
    )
  }
}

ImageTutorial.propTypes = {
  show_actions: PropTypes.bool //ensure state is a bool
};