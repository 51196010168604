import { ComposedChart , Line, Area, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, Label, Legend } from 'recharts';
import React, { Component } from 'react';
import "../../../css/Charts.css"
import { current_worldstate } from '../../backend/backend_manager';
import UserIcon from '../IconManager';


export default class TempChart extends Component {
    constructor(props) {
        super(props); // call the parent constructor
        this.state = {
            enlarge: false,
            clicked: false,
        };
    }

    enlarge() {
        this.setState({ enlarge: true })
    }

    delarge() {
        if (!this.state.clicked) {
            this.setState({ enlarge: false })
        }
    }

    render() {
        return (
            <>
                <div className="chart"
                    onMouseEnter={() => this.enlarge()}
                    onMouseLeave={() => this.delarge()}
                    onClick={() => this.setState({clicked: !this.state.clicked})}>
                    <div className="temp-top">
                        <UserIcon name="temperature" size="60px" />
                        Temperature and CO2 Emissions
                    </div>
                    <ComposedChart width={(this.state.enlarge) ? 600 : 300} height={200} data={this.props.data}>
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="Year" />
                        <YAxis yAxisId="left" domain={['auto', 'auto']}>
                            {/* <Label value="Temperature" angle={-90} textAnchor="end" /> */}
                        </YAxis>
                        <YAxis yAxisId="right" orientation="right" />
                        <Line type="monotone" yAxisId="left" dataKey="Temperature" stroke="#F3722C" dot={false} strokeWidth={3} />
                        <Line type="monotone" yAxisId="left" dataKey="Temperature_prediction" stroke="#F3722C" dot={false} strokeWidth={3} strokeDasharray="3 3" />
                        <Area type="monotone" yAxisId="right" dataKey="CO2_Emissions" fill="#277DA1" />
                        {/* <Bar type="monotone" yAxisId="right" dataKey="CO2_Concentration_prediction" fill="#277DA1" dot={false} strokeWidth={3} strokeDasharray="3 3" /> */}
                        <Tooltip />
                        <Legend verticalAlign="bottom" height={36} payload={[{ value: 'Temperature [° C]', type: 'line', id: 'Temperature', color: "#F3722C" }, { value: 'CO2 Emissions [t/year]', type: 'area', id: 'CO2_Emissions', color: "#277DA1" }]} />
                        <ReferenceLine x={current_worldstate.year} yAxisId="left" strokeWidth={2} stroke="#000000" ifOverflow="hidden" >
                            <Label value="Today" position="insideBottomLeft" />
                        </ReferenceLine>
                    </ComposedChart>
                </div>
            </>
        )
    }
}