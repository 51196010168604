import React, { Component } from 'react'
import { gridManager } from '../../backend/backend_manager';
import UserIcon from '../IconManager';

function ObjectStats(props) {
  if (props.item === null || !props.hovering) {
    return (<></>)
  }
  return (
    <div className="just-a-taste">
      <div className="info info-money">
        <UserIcon size="40px" name={(props.item.money_income_rate > 0) ? "coin_up" : "coin_down"} />
        <p>{props.item.money_income_rate} / s</p>
      </div>
      <div className="info info-energy">
        <UserIcon size="40px" name={(props.item.energy_production_rate > 0) ? "energy_up" : "energy_down"} />
        <p>{props.item.energy_production_rate} / s</p>
      </div>
      <div className="info info-co2">
        <UserIcon size="40px" name={(props.item.co2_production_rate > 0) ? "co2_up" : "co2_down"} />
        <p>{props.item.co2_production_rate} / s</p>
      </div>
    </div>
  );
}

export default class GridTile extends Component {
  constructor(props) {
    super(props); // call the parent constructor
    this.state = {
      row: Math.floor(this.props.index / 8),
      col: this.props.index % 8,
      hover_icon: null,
      hovering: false
    }
  }

  handleClick() {
    this.props.placeItem(this.state.row, this.state.col);
    // exploit mouseout to deselect hover icon
    this.mouseOut();
  }

  mouseIn() {
    this.setState({hovering: true})
    if (gridManager.selected != null) {
      if (this.props.item == null) {
        this.setState({ hover_icon: gridManager.selected.icon });
      }
      else {
        // trigger rerender for background
        // this.setState({ stat_display: 1});
        this.forceUpdate();
      }
    }
  }

  mouseOut() {
    this.setState({ hover_icon: null, hovering: false });
  }

  getClassName() {
    var cName = 'tile'
    // check if we're trying to place something
    if (gridManager.selected != null) {
      cName += ' selecting'
    }
    if (gridManager.bulldozing === true) {
      cName += ' bulldozing'
    }
    // check if there is already something on this field
    if (this.props.item != null) {
      cName += ' occupied'
    }
    return cName;
  }

  render() {
    //Check for the case of empty empty tile
    var placed_icon = (this.props.item === null) ? null : this.props.item.icon;
    var icon = (this.state.hover_icon === null) ? placed_icon : this.state.hover_icon;
    return (
      <>
        <div 
          class={this.getClassName()}
          onClick={() => this.handleClick()}
          onMouseEnter={() => this.mouseIn()}
          onMouseLeave={() => this.mouseOut()}>
          {icon}
        <ObjectStats item={this.props.item} hovering={this.state.hovering} />
        </div>
      </>
    )
  }
};