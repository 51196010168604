import Worldstate, { total_timesteps } from './worldstate';
import item_manager from './item_manager';
import { grid_manager } from './grid_manager';

var worldstate_history;
var current_worldstate;

var itemManager = new item_manager();
var gridManager = new grid_manager();

var engine;

var app;

function initBackend(app1) {
    app = app1;
    worldstate_history = [];
    // initial world state, values don't matter as the get overwritten by update
    current_worldstate = new Worldstate(0, 400, 20, 500, 500);
    current_worldstate.update();
    //console.log(current_worldstate.toString())
    //Add other stuff
    start_engine();
}

//Adds current_worldstate to history and calculates new one
function nextTimestep() {
    worldstate_history.push(current_worldstate);
    current_worldstate = current_worldstate.next;
    itemManager.check_item_cost();
    app.update();
}


//Starts engine that runs time and resources
function start_engine() {
    if (engine == null) {
        engine = setInterval(function () {
            nextTimestep();
            //console.log(current_worldstate.toString());
        }, 1000)
    }
}

//Stops engine that runs time and resources
function stop_engine() {
    if (engine != null) {
        clearInterval(engine);
        engine = null;
    }
}

//Returns Array of worldstates predicted from current worldstate
function getGraphData() {
    var data = []
    var iterate_worldstate = current_worldstate
    for (var i = 0; i < total_timesteps; i++) {
        if (i < worldstate_history.length) {
            iterate_worldstate = worldstate_history[i]
            data.push({ Year: iterate_worldstate.year, Temperature: iterate_worldstate.round_temp, CO2_Emissions: iterate_worldstate.round_co2_rate })
        }
        else if (i === worldstate_history.length) {
            iterate_worldstate = current_worldstate
            data.push({ Year: iterate_worldstate.year, Temperature: iterate_worldstate.round_temp, CO2_Emissions: iterate_worldstate.round_co2_rate })
        }
        else {
            iterate_worldstate = iterate_worldstate.next;
            data.push({ Year: iterate_worldstate.year, Temperature_prediction: iterate_worldstate.round_temp, CO2_Emissions_prediction: iterate_worldstate.round_co2_rate })
        }
    }
    return data;
}

export { initBackend, current_worldstate, itemManager, gridManager, getGraphData, start_engine, stop_engine };

