import React, { Component } from 'react';
import "../../css/PauseScreen.css";

export default class PauseScreen extends Component {
  constructor(props) {
    super(props); // call the parent constructor
    this.state = {
    }; 
  }

  render () {
    return (
      <>
      <div className='pause-screen' >
        <div className='pause-content'>
          PAUSED
          <div className="continue-button" onClick={() => this.props.unpause()}> 
          Continue
          </div>
        </div>
      </div>
      </>
    );
  }
}