import * as React from 'react';
import PropTypes from 'prop-types';
import * as IoIcons from "react-icons/io"
import Card from "./Card"
import { itemManager } from '../../backend/backend_manager';
import UserIcon from '../IconManager';


function TabPanel(props) {
  const { children, type } = props;

  return (
    <div
      className={"buy-tabpanel " + type}
      role="tabpanel"
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
};

function ObjectInformation(props) {
  const { item } = props;

  return (
    <div className="gameobject-information-wrapper">
      {/*only fill if we have an item*/}
      {item != null && (
        <>
          <div className="info-icon-large">
            {item.icon_large}
          </div>
          <div className="info-details">
            <div className="info-name">
              {item.name}
            </div>
            <div className="info-text">
              {item.text}
            </div>
            <div className="info-co2">
              <UserIcon size="40px" name={(item.co2_production_rate > 0) ? "co2_up" : "co2_down"} />
              {item.co2_production_rate} / s
            </div>
            <div className="info-money">
              <UserIcon size="40px" name={(item.money_income_rate > 0) ? "coin_up" : "coin_down"} />
              {item.money_income_rate} / s
            </div>
            <div className="info-energy">
              <UserIcon size="40px" name={(item.energy_production_rate > 0) ? "energy_up" : "energy_down"} />
              {item.energy_production_rate} / s
            </div>
          </div>
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
};

export default class BuyTabs extends React.Component {
  constructor(props) {
    super(props); // call the parent constructor
    this.state = {
      value: 0,
      selected_item: null,
    };
  }

  selectItem(item) {
    this.setState({ selected_item: item });
  }

  render() {
    return (
      <>
        <div className="buy-menu-titles">
          <div className='buy-title'>
            Climate
          </div>
          <div className='buy-title'>
            Energy
          </div>
          <div className='buy-title'>
            Money
          </div>
          <div className="close-buy-menu"
              onClick={() => this.props.selectAction("none")}>
            <IoIcons.IoMdClose />
          </div>
        </div>
        <div className='buy-menu-content-wrap'>
          <div className="buy-scrollable">
            <TabPanel type="carbon">
              {itemManager.carbonremovals.map((item, cardindex) => {
                return (
                  // we need to pass down the specific functions for the child elements to be able to call them
                  <Card item={item}
                    placeItem={() => this.props.selectAction("place")}
                    selectItem={(item) => this.selectItem(item)}
                    type="co2_down" />
                )
              })}
            </TabPanel>
            <TabPanel type="power">
              {itemManager.powercards.map((item, cardindex) => {
                return (
                  // we need to pass down the specific functions for the child elements to be able to call them
                  <Card item={item}
                    placeItem={() => this.props.selectAction("place")}
                    selectItem={(item) => this.selectItem(item)}
                    type="energy_up" />
                )
              })}
            </TabPanel>
            <TabPanel type="cash">
              {itemManager.cashmachines.map((item, cardindex) => {
                return (
                  // we need to pass down the specific functions for the child elements to be able to call them
                  <Card item={item}
                    placeItem={() => this.props.selectAction("place")}
                    selectItem={(item) => this.selectItem(item)}
                    type="coin_up" />
                )
              })}
            </TabPanel>
          </div>
          <ObjectInformation item={this.state.selected_item} />
        </div>
      </>
    );
  }
}