import { gridManager } from "./backend_manager";

//Balancing Number
const timesteps_per_year = 5;
const total_timesteps = 80 * timesteps_per_year + 1;
const emmisions2conc = 1 / 2; //Represents Air Volume in (ppm/t)

class Worldstate {
    constructor(timestep, co2_conc, temp, money, energy) {
        this._timestep = timestep;
        this._co2_conc = co2_conc; //in ppm
        this._temp = temp; // in °C
        this._money = money;
        this._energy = energy;
        this.update();
    }

    get co2_conc() {
        return this._co2_conc;
    }

    get timestep() {
        return this._timestep;
    }

    get energy() {
        return this._energy;
    }

    get money() {
        return this._money;
    }

    get energy_rate() {
        return this._energy_rate;
    }

    get money_rate() {
        return this._money_rate;
    }

    get co2_rate() {
        return this._co2_rate;
    }

    //Used for chart, rounded to 2 decimal due to beauty
    get round_co2_rate() {
        return Math.round(this._co2_rate * 100) / 100
    }

    get temp() {
        return this._temp;
    }

    //Used for chart, rounded to 3 decimal due to beauty
    get round_temp() {
        return Math.round(this._temp * 1000) / 1000
    }

    //Used for chart, rounded to 1 decimal due to beauty
    get year() {
        return Math.floor((2020 + this._timestep / timesteps_per_year) * 10) / 10
    }

    //Returns Worldstate at t+1 (recursive) (no rate changes)
    get next() {
        var new_co2_conc = this._co2_conc + this._co2_rate * emmisions2conc / timesteps_per_year;
        var new_temp = this._temp + 1.66 * Math.log(new_co2_conc / this._co2_conc);
        var new_money = Math.max(0, this._money + this._money_rate);
        var new_energy = Math.max(0, Math.min(this._energy + this._energy_rate, 2000));
        return new Worldstate(this._timestep + 1, new_co2_conc, new_temp, new_money, new_energy);
    }

    // substract the costs from the resources
    update_on_buy(world_object) {
        this._co2_conc += world_object.co2_cost * emmisions2conc;
        this._energy -= world_object.energy_cost;
        this._money -= world_object.cost;
        this.update();
    }

    // update the rates based on the grid content
    update() {
        // default rates, correspond to initial rate
        this._co2_rate = 30; //in t/year
        this._money_rate = 0;
        this._energy_rate = 0;
        for (var i = 0; i < gridManager.grid.length; i++) {
            if (gridManager.grid[i] != null) {
                var content = gridManager.grid[i];
                this._co2_rate = this._co2_rate + 10 + content.co2_production_rate; // +10 due to population increase
                this._money_rate += content.money_income_rate;
                this._energy_rate += content.energy_production_rate;
            }
        }
    }

    //Used for console
    toString() {
        return "Timestep " + this._timestep + "; CO2_rate: " + this._co2_rate + " t/year; CO2_Conc: " + this.co2_conc + " ppm; Temp: " + this._temp + " °C; Money: " + this._money + "; Moneyrate: " + this._money_rate + "; Energy: " + this._energy + "; Energyrate: " + this._energy_rate;
    }

}

export default Worldstate;
export { total_timesteps }