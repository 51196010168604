import React, { Component } from 'react';
import {ProgressBar } from 'react-bootstrap';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { itemManager } from '../../backend/backend_manager';
import '../../../css/Bars.css';
import UserIcon from '../IconManager';


export default class MoneyBar extends Component {

  render() {
    var money_x = this.props.worldstate.money/itemManager.getMaxCosts()
    // var energy_x = this.props.worldstate.energy/500
    return (
      <div className="Bars">
        <div className = "bar-container money-container">
            <ProgressBar className = "Bar MoneyBar"  now={money_x/(1+money_x)} max={1}  variant="warning"  />
          <p>Money: {this.props.worldstate.money} ({this.props.worldstate.money_rate} / s)</p>
          <div class="Icon MoneyIcon">
            <UserIcon name="coin" size="60px"/>
          </div>
        </div>

        {/* Commented out for testing phase */}
        
        <div className = "bar-container energy-container">
          <ProgressBar className = "Bar EnergyBar"  now={this.props.worldstate.energy} max={2000} variant="success" /> {/*now={energy_x/(1+energy_x)} max={1} Only use when energy is endlessly storable*/}
          <p>Energy: {this.props.worldstate.energy} ({this.props.worldstate.energy_rate} / s)</p>
          <div class="Icon EnergyIcon">
            <UserIcon name="energy" size="60px"/>
          </div>
        </div>
      </div>
    )
  }
}