import React, { Component } from 'react';
import UserIcon from '../IconManager';


export default class Bulldozer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // set the initial css for the drag items s.t they load properly
            unclicked: {
                visibility: 'hidden',
            },
            clickedStyle: {
                visibility: 'visible',
                position: 'fixed',
                margin: 0,
                top: 0, //item hovers 20px above cursor
                left: 0, //center item above cursor horizontally
            },
        };
    }

    componentDidMount() {
        // update mouse position on move for the drag icon
        window.addEventListener("mousemove", this.updateMousePosition);
    }

    updateMousePosition = e => {
        this.setState({
            clickedStyle: {
                position: 'fixed',
                margin: 0,
                top: e.clientY - 80, //item hovers 20px above cursor
                left: e.clientX - 80, //center item above cursor horizontally
            }
        });
    }

    render() {
        return (
            <>
            {this.props.drag &&
            /* gets only executed when above condition was true*/
            <div style={this.props.drag ? this.state.clickedStyle : this.state.unclicked}>
                <UserIcon name="bulldozer"/>
            </div>
            }
            </>
        )
    }
}