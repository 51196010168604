import React from 'react'

export default function UserIcon(props) {
  var size
  if (props.size != null) {
      size = props.size
  } else {
    size = "75px"
  }
  return (
    <img style={{height: size, width: size}}
        src={process.env.PUBLIC_URL+'./textures/'+ props.name + ".png"}
        alt={props.name}/>
  )
}

export function Tutorial(props) {
  return (
    <img style={{maxWidth: "100%", maxHeight: "90%", objectFit: "cover"}}
        src={process.env.PUBLIC_URL+'./tutorial/'+ props.name + ".png"}
        alt={props.name} />
  )
}