import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { gridManager, stop_engine } from '../backend/backend_manager';
import * as IoIcons from "react-icons/io"
import '../../css/ActionMenu.css'
import UserIcon from './IconManager';

export default class ActionMenu extends Component {

  abort() {
    this.props.selectAction("none")
  }
  // change the state and hence trigger a rerender
  selectBuyAction() {
    // also aborts previous actions:
    this.props.selectAction("buy")
  }

  selectRemoveAction() {
    // also aborts previous actions:
    this.props.selectAction("remove")

    if (!gridManager.bulldozing) {
      gridManager.setBulldozing(true);
    }
  }

  // change the state and hence trigger a rerender
  selectTutorialAction() {
    // also aborts previous actions:
    stop_engine();
    this.props.selectAction("tutorial");
  }

  render() {
    return (
      // start html:
      <>
        <div className={this.props.show_actions ? 'action-menu show' : 'action-menu'}>
          {/* open buy menu: */}
          <button onClick={() => this.selectBuyAction()}
            className='open-buy-menu'>
            {/*this.state.show_menu ? 'toggle-buy-menu' : 'toggle-buy-menu show'*/}
            <UserIcon name="build" />
            Build
          </button>
          {/* trigger bulldozer */}
          <button onClick={() => this.selectRemoveAction()}
            className='remove-button'>
            {/*this.state.show_menu ? 'toggle-buy-menu' : 'toggle-buy-menu show'*/}
            <UserIcon name="bulldozer" />
            Remove
          </button>
          {/* open tutorial: */}
          <button onClick={() => this.selectTutorialAction()}
            className="help-button">
            {/*this.state.show_menu ? 'toggle-buy-menu' : 'toggle-buy-menu show'*/}
            <IoIcons.IoIosHelpCircle style={{ color: 'white' }} size={70} />
            Help
          </button>
        </div>
        <button className={this.props.show_actions ? 'abort' : 'abort show'}
          onClick={() => this.abort()}>
          <IoIcons.IoMdClose style={{ color: 'white' }} size={70} />
        </button>
      </> // end html
    )
  }
}

ActionMenu.propTypes = {
  show_actions: PropTypes.bool //ensure state is a bool
};