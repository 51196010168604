import React, { Component } from 'react'
import '../../css/Grid.css'
import GridTile from './grid_components/GridTile';


export default class Grid extends Component {
  render() {
    return (
      <div class="wrapper">
         <div class="floor"
            onMouseEnter={() => this.props.outsideOfGrid(false)}
            onMouseLeave={() => this.props.outsideOfGrid(true)}>
            {this.props.grid_content.map((item, index) => {
                  // check if there is an item at this position and get its icon
                  const prop_item = item;
                  return(
                     <GridTile index={index} item={prop_item} placeItem={(row, col) => this.props.placeItem(row, col)}/>
                  )
            })}
         </div>
      </div>
    )
  }
};